<template>
    <nav class="navbar navbar-expand-lg navbar-light bg-light">
        <router-link class="navbar-brand" :to="{ path: '/' }">
            <img src="https://medshift-cdn.nyc3.cdn.digitaloceanspaces.com/MEDSHIFT+Transparent.png"
                 width="85"
                 class="d-inline-block align-top"
                 alt="MedShift">
        </router-link>
        <button class="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarNav">
            <span class="navbar-toggler-icon" />
        </button>
        <div class="collapse navbar-collapse" id="navbarNav">
            <ul class="navbar-nav">
                <li class="nav-item" v-if="hasPermissions('read:products')">
                    <router-link class="nav-link ml-3" :to="{ name: 'product-list' }">
                        Products
                    </router-link>
                </li>
                <li class="nav-item" v-if="hasPermissions('read:shipments')">
                    <router-link class="nav-link ml-3" :to="{ name: 'shipments-list' }">
                        Shipments
                    </router-link>
                </li>
                <li class="nav-item" v-if="hasPermissions('create:shipments:order_fulfillment')">
                    <router-link class="nav-link ml-3" :to="{ name: 'order-list' }">
                        Orders
                    </router-link>
                </li>
                <li class="nav-item dropdown ml-3"
                    style="cursor: pointer"
                    v-if="hasPermissions('read:reports')">
                    <div class="nav-link dropdown-toggle" data-toggle="dropdown">Reports</div>
                    <div class="dropdown-menu dropdown-menu-right">
                        <router-link class="dropdown-item" :to="{ name: 'payout-reports' }">
                            Payout batches
                        </router-link>
                    </div>
                </li>
                <li class="nav-item dropdown ml-3"
                    style="cursor: pointer"
                    v-if="hasSomePermission('modify:items', 'create:shipments:quick_ship', 'read:settings:package_dimensions')">
                    <div class="nav-link dropdown-toggle" data-toggle="dropdown">Options</div>
                    <div class="dropdown-menu dropdown-menu-right">
                        <router-link class="dropdown-item"
                                     :to="{ name: 'quick-scan' }"
                                     v-if="hasPermissions('modify:items')">
                            Quick scan
                        </router-link>
                        <router-link class="dropdown-item"
                                     :to="{ name: 'quick-ship' }"
                                     v-if="hasPermissions('create:shipments:quick_ship')">
                            Quick ship
                        </router-link>
                        <router-link class="dropdown-item"
                                     :to="{ name: 'package-dimensions' }"
                                     v-if="hasPermissions('read:settings:package_dimensions')">
                            Package dimensions
                        </router-link>
                    </div>
                </li>
                <li class="nav-item dropdown ml-3"
                    style="cursor: pointer"
                    v-if="hasSomePermission('read:tools:sales_sync', 'read:tools:report_transactions')">
                    <div class="nav-link dropdown-toggle" data-toggle="dropdown">Tools</div>
                    <div class="dropdown-menu dropdown-menu-right">
                        <router-link class="dropdown-item"
                                     :to="{ name: 'sales-sync' }"
                                     v-if="hasPermissions('read:tools:sales_sync')">
                            Sales Sync
                        </router-link>
                        <router-link class="dropdown-item"
                                     :to="{ name: 'create-transaction' }"
                                     v-if="hasPermissions('read:tools:report_transactions')">
                            Report Transactions
                        </router-link>
                    </div>
                </li>
            </ul>
            <ul class="navbar-nav ml-auto">
                <li class="nav-item">
                    <ul class="pl-0 dropdown">
                        <li style="cursor: pointer"
                            class="nav-link dropdown-toggle"
                            data-toggle="dropdown">
                            <img v-if="userPictureSrc"
                                 :src="userPictureSrc"
                                 alt=""
                                 class="rounded-circle shadow-sm mr-1"
                                 height="28px">
                            <i v-else class="fa fa-user-circle mr-2" />
                            {{ username }}
                        </li>
                        <div class="dropdown-menu dropdown-menu-right">
                            <span style="cursor: pointer"
                                  @click="logout"
                                  class="dropdown-item">
                                Logout
                            </span>
                        </div>
                    </ul>
                </li>
            </ul>
        </div>
    </nav>
</template>

<script>
    import { MEDSHIFT_LOGO } from '@/constants';
    import { logout } from '@/services/AuthService';
    import { hasPermissions, hasSomePermission } from '@/helpers/permissionUtils';

    export default {
        name: 'TheNavbar',
        data() {
            return {
                username: '',
                userPictureSrc: null,
                medShiftLogo: MEDSHIFT_LOGO
            };
        },
        created() {
            this.username = localStorage.getItem('username');
            this.userPictureSrc = localStorage.getItem('user_picture');
        },
        methods: {
            logout() {
                logout();
                this.$router.push({ name: 'app-login' });
            },
            hasPermissions,
            hasSomePermission,
        }
    };
</script>
