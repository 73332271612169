import Vue from 'vue';
import Router from 'vue-router';

import { login, logout } from './services/AuthService';
import { hasPermissions } from './helpers/permissionUtils';

Vue.use(Router);

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        // LOGIN ROUTES
        {
            path: '/auth0/authorized',
            name: 'auth0-authorized',
            beforeEnter: async (to, from, next) => await login(to, next)
        },
        {
            path: '/login',
            name: 'app-login',
            component: () => import('./views/AppLogin.vue')
        },

        // PRODUCT ROUTES
        {
            path: '/products',
            name: 'product-list',
            component: () => import('./views/products/ProductList.vue'),
            meta: {
                requiresAuth: true,
                requiresPermission: 'read:products',
            }
        },
        {
            path: '/products/new',
            name: 'product-add',
            component: () => import('./views/products/ProductAddOrUpdate.vue'),
            meta: {
                requiresAuth: true,
                requiresPermission: 'modify:products',
            }
        },
        {
            path: '/products/:id/edit',
            name: 'product-edit',
            component: () => import('./views/products/ProductAddOrUpdate.vue'),
            meta: {
                requiresAuth: true,
                requiresPermission: 'modify:products',
            }
        },
        {
            path: '/products/:id/import',
            name: 'product-import-items',
            component: () => import('./views/products/ProductImportItems.vue'),
            meta: {
                requiresAuth: true,
                requiresPermission: 'modify:products',
            }
        },
        {
            path: '/products/:id/items',
            name: 'product-items',
            component: () => import('./views/products/ProductItems.vue'),
            meta: {
                requiresAuth: true,
                requiresPermission: 'read:items',
            }
        },
        {
            path: '/products/:product_id/items/:item_id',
            name: 'product-item-details',
            component: () => import('./views/products/ProductItemDetails.vue'),
            meta: {
                requiresAuth: true,
                requiresPermission: 'read:items',
            }
        },
        {
            path: '/products/:id/fields',
            name: 'product-fields',
            component: () => import('./views/products/ProductFields.vue'),
            meta: {
                requiresAuth: true,
                requiresPermission: 'read:products',
            }
        },

        // SHIPMENT ROUTES
        {
            path: '/shipments/',
            name: 'shipments-list',
            component: () => import('./views/shipments/ShipmentList.vue'),
            meta: {
                requiresAuth: true,
                requiresPermission: 'read:shipments',
            },
            props: true
        },
        {
            path: '/shipments/:id',
            name: 'shipment-details',
            component: () => import('./views/shipments/ShipmentDetails.vue'),
            meta: {
                requiresAuth: true,
                requiresPermission: 'read:shipments',
            }
        },

        // ORDERS ROUTES
        {
            path: '/orders/',
            name: 'order-list',
            component: () => import('./views/orders/OrderList.vue'),
            meta: {
                requiresAuth: true,
                requiresPermission: 'create:shipments:order_fulfillment',
            }
        },
        {
            path: '/orders/:id',
            name: 'order-fulfillment',
            component: () => import('./views/orders/OrderFulfillment.vue'),
            meta: {
                requiresAuth: true,
                requiresPermission: 'create:shipments:order_fulfillment',
            }
        },

        // REPORTS ROUTES
        {
            path: '/payout-reports',
            name: 'payout-reports',
            component: () => import('./views/reports/PayoutBatchReports.vue'),
            meta: {
                requiresAuth: true,
                requiresPermission: 'read:reports',
            }
        },

        // OPTIONS ROUTES
        {
            path: '/quick-scan',
            name: 'quick-scan',
            component: () => import('./views/options/QuickScan.vue'),
            meta: {
                requiresAuth: true,
                requiresPermission: 'modify:items',
            }
        },
        {
            path: '/quick-ship',
            name: 'quick-ship',
            component: () => import('./views/options/QuickShip.vue'),
            meta: {
                requiresAuth: true,
                requiresPermission: 'create:shipments:quick_ship',
            }
        },
        {
            path: '/package-dimensions',
            name: 'package-dimensions',
            component: () => import('./views/options/PackageDimensions.vue'),
            meta: {
                requiresAuth: true,
                requiresPermission: 'read:settings:package_dimensions',
            }
        },
        // TOOLS ROUTES
        {
            path: '/sales-sync',
            name: 'sales-sync',
            component: () => import('./views/tools/SalesSync.vue'),
            meta: {
                requiresAuth: true,
                requiresPermission: 'read:tools:sales_sync',
            }
        },
        {
            path: '/transactions/create',
            name: 'create-transaction',
            component: () => import('./views/tools/CreateTransaction.vue'),
            meta: {
                requiresAuth: true,
                requiresPermission: 'read:tools:report_transactions',
            }
        },
        // CATCH ALL ROUTE
        {
            path: '*',
            redirect: '/login'
        }
    ]
});

// Check user access before each route
router.beforeEach(async (to, from, next) => {
    const loggedIn = !!localStorage.getItem('token');
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);

    if (requiresAuth) {
        if (!loggedIn) {
            next('login');
            return;
        }

        // Automatically log out users that don't have permissions stored
        if ((localStorage.getItem('user_perms') || '').length <= '[]'.length) {
            logout();
            next('login');
            return;
        }

        // Check that users have the required permission to view a page
        if (!userHasPermissionsForRoute(to)) {
            console.warn('Insufficient permissions');
            next('/');
            return;
        }
    } else {
        if (loggedIn) {
            // Navigate the user to the first page that they have permissions for
            const pages = ['/products', '/shipments', '/orders', '/quick-scan', '/package-dimensions'];
            const nextPage = pages.find(path => {
                const route = router.resolve(path).route;
                return userHasPermissionsForRoute(route);
            });
            next(nextPage);
            return;
        }
    }

    next();
});

const userHasPermissionsForRoute = (route) => {
    const requiredPermissions = route.matched
        .flatMap(route => route.meta.requiresPermission)
        .filter(perm => typeof perm === 'string' && perm.length > 0);
    return hasPermissions(...requiredPermissions);
}

export default router;
