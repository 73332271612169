import axios from 'axios';

export const login = async (to, next) => {
    try {
        // Check if the state paramater matches to prevent CSRF
        const state = sessionStorage.getItem('auth_state');
        sessionStorage.removeItem('auth_state');
        if (!to.query.state || !state || to.query.state !== state) {
            throw Error('Invalid state paramater in authentication');
        }

        const response = await axios.post('api/login/social/token_user/', {
            provider: 'auth0',
            code: to.query.code,
        });

        localStorage.setItem('username', `${response.data.first_name} ${response.data.last_name}`);
        localStorage.setItem('user_id', response.data.id);
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('user_perms', JSON.stringify(response.data.permissions)); // ONLY for client-side hiding
        localStorage.setItem('user_picture', response.data.picture);

        next('products-list');
    } catch (error) {
        console.error(error);
        next('app-login');
    }
}

export const logout = () => {
    localStorage.clear();
}

const generateRandomState = () => {
    // Characters that we will use for the state (64 = 6 bit each)
    const ALPHABET = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-_';

    const bytes = new Uint8Array(20);
    crypto.getRandomValues(bytes);
    const array = Array.from(bytes); // Needs to be a non-typed array to map with strings

    // We take only the last 6 bits of each value (discards 2 bits of entropy each)
    return array.map(b => ALPHABET[b & 63]).join('');
}

export const navigateToLogin = () => {
    const state = generateRandomState();
    sessionStorage.setItem('auth_state', state);
    window.location = `https://${process.env.VUE_APP_AUTH0_DOMAIN}/authorize?response_type=code` +
        `&client_id=${process.env.VUE_APP_AUTH0_CLIENT_ID}` +
        `&redirect_uri=${process.env.VUE_APP_REDIRECT_URL}` +
        `&audience=${process.env.VUE_APP_AUTH0_API_AUDIENCE}` +
        `&scope=openid%20profile%20email` +
        `&state=${state}`;
}
