/**
 * Returns `true` iff the user has *all* of the specified permissions
 */
export const hasPermissions = (...permissions) => {
    const userPermissions = JSON.parse(localStorage.getItem('user_perms')) || [];
    return permissions.every(perm => userPermissions.includes(perm))
}

/**
 * Returns `true` iff the user has *any* of the specified permissions
 */
export const hasSomePermission = (...permissions) => {
    const userPermissions = JSON.parse(localStorage.getItem('user_perms')) || [];
    return permissions.some(perm => userPermissions.includes(perm));
}
