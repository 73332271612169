<template>
    <div id="app" class="blue-gradient">
        <TheNavbar v-if="showNavbar" />
        <transition name="fade" mode="out-in">
            <router-view></router-view>
        </transition>
    </div>
</template>

<script>
import TheNavbar from './components/TheNavbar.vue';

export default {
    name: 'App',
    components: {
        TheNavbar
    },
    computed: {
        showNavbar() {
            return this.$route.name !== 'login' && !!localStorage.getItem('token');
        }
    }
};
</script>

<style>
/* ROUTE TRANSITIONS */
.fade-enter-active,
.fade-leave-active {
    transition-duration: 0.25s;
    transition-property: opacity;
    transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
    opacity: 0;
}

/* MODAL STYLES */
.modal-dialog {
    max-width: 600px;
}

/* TABLE STYLES */
.table {
    color: #5a6169 !important;
}

.table th,
.table td {
    vertical-align: middle !important;
}

.table th {
    font-weight: 500;
}

.table-fixed {
    table-layout: fixed;
}

.table-row {
    cursor: pointer;
}

.table-row:hover {
    background: #f5f5f5;
}

/* DATATABLE STYLES */
table.dataTable thead th {
    outline: none;
    cursor: pointer;
}

.dataTables_wrapper {
    padding: 0 !important;
}

ul.pagination {
    margin-top: 45px;
    margin-bottom: 15px;
}

/* TOGGLE CHECKBOX */
.custom-control-input:checked ~ .custom-control-label::before {
    background-color: rgb(21, 143, 208);
}

.cursor-pointer {
    cursor: pointer;
}

.vh-75 {
    height: 75vh;
}
</style>

