import axios from 'axios';

export default function interceptorSetup() {
    axios.interceptors.request.use(
        config => {
            config.baseURL = process.env.VUE_APP_BASE_URL;
            const token = localStorage.getItem('token');
            
            if (token) {
                config.headers.Authorization = `Token ${token}`;
            }
            return config;
        },
        err => Promise.reject(err)
    );
}
